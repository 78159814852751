import {config} from "@clout-team/web-components";

export const meetLog =
  (window.location.host !== config.DOMAIN_MEET) ?
    console.log.bind(console) :
    (...args) => {}

    export const getDate = () => {
      const dateFormat= new Date();
      return dateFormat.getDate()+
        "-"+(dateFormat.getMonth()+1)+
        "-"+dateFormat.getFullYear()+
        " "+dateFormat.getHours()+
        ":"+dateFormat.getMinutes()+
        ":"+dateFormat.getSeconds();
    }
